import React, { useCallback, useState } from "react";
import { NeutralColors } from "@fluentui/theme";

import {
  Image,
  ImageFit,
  Link,
  Modal,
  ResponsiveMode,
  Stack,
  Text,
} from "@fluentui/react";

import { Card } from "@fluentui/react-cards";

import ReactImageVideoLightbox from "react-image-video-lightbox";
import Container from "components/Container";
import Header from "components/Header";

import imgBackground1 from "assets/images/background-1.png";
import iconLaptop3 from "assets/images/icons/icon-laptop-3.png";
import iconName from "assets/images/icons/icon-name.png";
import iconMap from "assets/images/icons/icon-map.png";
import iconCode2 from "assets/images/icons/icon-code-2.png";
import iconScreen4 from "assets/images/icons/icon-screen-4.png";
import iconMedal1 from "assets/images/icons/icon-medal-1.png";
import iconGamepad1 from "assets/images/icons/icon-gamepad-1.png";
import iconOverseas1 from "assets/images/icons/icon-overseas-1.png";

import useResponsiveMode from "hooks/useResponsiveMode";

import awards from "articles/awards";
import projects from "articles/projects";
import experiences from "articles/experiences";
import styled from "styled-components";
import Articles from "./Articles";

const Banner = styled.div`
  width: 100%;
  height: auto;
  background-color: #333333;
  color: #3798fa;
  font-size: 20px;
  padding: 16px;
  text-align: center;
`;

export default () => {
  const responsiveMode = useResponsiveMode();
  const [shownArticle, setShownArticle] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleDismissModal = useCallback(() => {
    setIsOpenModal(false);
    setTimeout(() => {
      if (!isOpenModal) {
        setShownArticle(null);
      }
    }, 350);
  }, [isOpenModal]);

  const handleClickArticle = useCallback((article) => {
    setShownArticle(article);
    setTimeout(() => {
      setIsOpenModal(true);
    }, 10);
  }, []);
  return (
    <>
      <Banner>
        This website was created in 2020 and contains outdated information.
        <br />
        See you soon with a new look.
      </Banner>
      <Header />
      <div
        style={{
          marginBottom: 8,
          backgroundColor: "#213147",
          backgroundImage: `url(${imgBackground1})`,
          backgroundSize: "auto 100%",
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Stack
            horizontalAlign="start"
            verticalAlign="end"
            style={{
              minHeight: "36vh",
              backgroundColor: "transparent",
              color: NeutralColors.white,
            }}
            tokens={{ padding: 16 }}
          >
            <Stack.Item>
              <Text
                variant="xxLargePlus"
                className="ms-motion-fadeIn"
                style={{ animationDelay: "0s", marginBottom: 8 }}
              >
                Maro
              </Text>
            </Stack.Item>
            <Stack.Item>
              <Text
                variant="xxLarge"
                className="ms-motion-fadeIn"
                style={{ animationDelay: ".1s" }}
              >
                Code Revolutionist
              </Text>
            </Stack.Item>
            <Stack.Item>
              <Text
                variant="xLarge"
                className="ms-motion-fadeIn"
                style={{ animationDelay: ".2s" }}
              >
                Web-based Software Engineer
              </Text>
            </Stack.Item>
          </Stack>
        </Container>
      </div>

      <Container style={{ marginBottom: 96 }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="start"
          wrap
        >
          <Stack.Item
            styles={{
              root: {
                width: responsiveMode >= ResponsiveMode.xLarge ? "25%" : "50%",
                maxWidth: 320,
                padding: 8,
              },
            }}
          >
            <Card
              tokens={{
                width: "100%",
                padding: 16,
                minWidth: "unset",
                maxWidth: "unset",
              }}
            >
              <Card.Item>
                <Image
                  src={iconMap}
                  width="100%"
                  height={96}
                  imageFit={ImageFit.contain}
                />
              </Card.Item>
              <Card.Section styles={{ root: { textAlign: "center" } }}>
                <Text variant="mediumPlus">Location</Text>
                <Text block>Gangnam-gu, Seoul, Korea</Text>
              </Card.Section>
            </Card>
          </Stack.Item>
          <Stack.Item
            styles={{
              root: {
                width: responsiveMode >= ResponsiveMode.xLarge ? "25%" : "50%",
                maxWidth: 320,
                padding: 8,
              },
            }}
          >
            <Card
              tokens={{
                width: "100%",
                padding: 16,
                minWidth: "unset",
                maxWidth: "unset",
              }}
            >
              <Card.Item>
                <Image
                  src={iconCode2}
                  width="100%"
                  height={96}
                  imageFit={ImageFit.contain}
                />
              </Card.Item>
              <Card.Section styles={{ root: { textAlign: "center" } }}>
                <Text variant="mediumPlus">Currently most used</Text>
                <Text block>React</Text>
              </Card.Section>
            </Card>
          </Stack.Item>
          <Stack.Item
            styles={{
              root: {
                width: responsiveMode >= ResponsiveMode.xLarge ? "25%" : "50%",
                maxWidth: 320,
                padding: 8,
              },
            }}
          >
            <Card
              tokens={{
                width: "100%",
                padding: 16,
                minWidth: "unset",
                maxWidth: "unset",
              }}
            >
              <Card.Item>
                <Image
                  src={iconMedal1}
                  width="100%"
                  height={96}
                  imageFit={ImageFit.contain}
                />
              </Card.Item>
              <Card.Section styles={{ root: { textAlign: "center" } }}>
                <Text variant="mediumPlus">Awards</Text>
                <Text block>28+</Text>
              </Card.Section>
            </Card>
          </Stack.Item>
          <Stack.Item
            styles={{
              root: {
                width: responsiveMode >= ResponsiveMode.xLarge ? "25%" : "50%",
                maxWidth: 320,
                padding: 8,
              },
            }}
          >
            <Card
              tokens={{
                width: "100%",
                padding: 16,
                minWidth: "unset",
                maxWidth: "unset",
              }}
            >
              <Card.Item>
                <Image
                  src={iconScreen4}
                  width="100%"
                  height={96}
                  imageFit={ImageFit.contain}
                />
              </Card.Item>
              <Card.Section styles={{ root: { textAlign: "center" } }}>
                <Text variant="mediumPlus">Projects</Text>
                <Text block>34+</Text>
              </Card.Section>
            </Card>
          </Stack.Item>
        </Stack>
      </Container>

      <Container style={{ marginBottom: 48 }}>
        <Stack horizontal={responsiveMode > ResponsiveMode.large}>
          <Stack.Item grow={1} styles={{ root: { marginBottom: 48 } }}>
            <Stack>
              <Stack.Item>
                <Stack
                  horizontal
                  verticalAlign="center"
                  style={{ marginBottom: 24 }}
                >
                  <Stack.Item>
                    <Image src={iconName} width={64} />
                  </Stack.Item>
                  <Stack.Item>
                    <Text variant="xLarge">Greetings</Text>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
              <Stack.Item styles={{ root: { paddingLeft: 24 } }}>
                <Text variant="medium">
                  찾아주셔서 감사합니다.
                  <br />
                  <br />
                  모르는 것을 아는 것 처럼 말하지 않습니다.
                  <br />
                  어떤 환경에서든 창의적인 힘을 발휘합니다.
                  <br />
                  내가 사랑할 수 있는 소프트웨어를 개발합니다.
                  <br />
                  <br />
                  오늘보다 내일이 다른 소프트웨어 엔지니어, 마로입니다.
                </Text>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item grow={1} styles={{ root: { marginBottom: 48 } }}>
            <Stack>
              <Stack.Item>
                <Stack
                  horizontal
                  verticalAlign="center"
                  style={{ marginBottom: 24 }}
                >
                  <Stack.Item>
                    <Image src={iconMap} width={64} />
                  </Stack.Item>
                  <Stack.Item>
                    <Text variant="xLarge">&nbsp;&nbsp;Where I live...</Text>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
              <Stack.Item styles={{ root: { paddingLeft: 24 } }}>
                <Text variant="medium">
                  대한민국의 서울, <br />
                  그 안에서도 강남구에 거주하고 있습니다.
                  <br />
                  <br />
                  함께 커피 한 잔 마시면서 이야기 하실 분<br />
                  언제나 환영이에요!
                  <br />
                  <br />
                  <Link
                    href="mailto:honeymaro@live.co.kr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    월드 와이드 웹 에서의 소통
                  </Link>
                  도 항상 열려있습니다.
                  <br />
                </Text>
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </Container>

      <Container style={{ marginBottom: 96 }}>
        <Stack>
          <Stack.Item>
            <Stack
              horizontal
              verticalAlign="center"
              style={{ marginBottom: 24 }}
            >
              <Stack.Item>
                <Image src={iconLaptop3} width={64} />
              </Stack.Item>
              <Stack.Item>
                <Text variant="xLarge">
                  &nbsp;&nbsp;What I'm interested in...
                </Text>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item styles={{ root: { paddingLeft: 24 } }}>
            <Text variant="mediumPlus" style={{ fontWeight: 700 }}>
              Interactive User Experience
              <br />
              <br />
            </Text>
            <Text variant="medium" block style={{ marginBottom: 16 }}>
              모니터 속의 경험 뿐만 아니라 현실 세계에 적용할 수 있는 사용자
              경험에 흥미가 있습니다.
              <br />
              디바이스와 디바이스가 상호작용하는 사용자 경험을 연구하는 것을
              즐깁니다.
              <br />
            </Text>
            <Stack
              horizontal
              horizontalAlign="start"
              verticalAlign="center"
              tokens={{ childrenGap: 16 }}
              styles={{
                root: {
                  overflowX: "auto",
                  width: "100%",
                  paddingBottom: 16,
                },
              }}
            >
              <Articles
                articles={(awards || []).map((award) => {
                  const newAward = { ...award };
                  newAward.name = `${award.name}, ${award.prize}`;
                  return newAward;
                })}
                onClick={handleClickArticle}
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </Container>

      <Container style={{ marginBottom: 96 }}>
        <Stack>
          <Stack.Item>
            <Stack
              horizontal
              verticalAlign="center"
              style={{ marginBottom: 24 }}
            >
              <Stack.Item>
                <Image src={iconGamepad1} width={64} />
              </Stack.Item>
              <Stack.Item>
                <Text variant="xLarge">
                  Interface of the users, by the users, for the users
                </Text>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item styles={{ root: { paddingLeft: 24 } }}>
            <Text variant="medium" block style={{ marginBottom: 16 }}>
              누구나 쉽게 알아차릴 수 있는 인터페이스를 중요시 합니다.
              <br />
              모두가 사용할 수 있는 소프트웨어부터 특정 유저를 위한
              소프트웨어까지 다양한 개발 경험을 갖고 있습니다.
              <br />
            </Text>
            <Articles articles={projects} onClick={handleClickArticle} />
          </Stack.Item>
        </Stack>
      </Container>

      <Container style={{ marginBottom: 96 }}>
        <Stack>
          <Stack.Item>
            <Stack
              horizontal
              verticalAlign="center"
              style={{ marginBottom: 24 }}
            >
              <Stack.Item>
                <Image src={iconOverseas1} width={64} />
              </Stack.Item>
              <Stack.Item>
                <Text variant="xLarge">Anywhere in the world</Text>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item styles={{ root: { paddingLeft: 24 } }}>
            <Text variant="medium" style={{ marginBottom: 16 }}>
              단순 '코딩' 뿐만 아니라 다양한 인종, 언어, 문화의 개발자 및
              엔지니어들과 만남으로 견문을 넓혀가고 있습니다.
              <br />
              <br />
            </Text>
            <Articles articles={experiences} onClick={handleClickArticle} />
          </Stack.Item>
        </Stack>
      </Container>

      <div style={{ backgroundColor: "#213147", color: NeutralColors.white }}>
        <Container>
          <Stack
            horizontalAlign="center"
            verticalAlign="center"
            tokens={{ padding: "24px 0", childrenGap: 4 }}
          >
            <Stack.Item>© maro</Stack.Item>
            <Stack.Item>
              illustrated by{" "}
              <Link
                href="https://www.instagram.com/hyun_jihun/"
                target="_blank"
                rel="noopener noreferrer"
              >
                @hyun_jihun
              </Link>
            </Stack.Item>
            <Stack.Item>
              Previous version:&nbsp;
              <Link
                href="https://2019.maro.page"
                target="_blank"
                rel="noopener noreferrer"
              >
                2019
              </Link>
            </Stack.Item>
          </Stack>
        </Container>
      </div>

      <Modal
        isOpen={isOpenModal}
        onDismiss={handleDismissModal}
        isBlocking={false}
      >
        <div
          style={{
            position: "relative",
            width: "80vw",
            height: "80vh",
            maxWidth: 720,
            maxHeight: 480,
          }}
        >
          <ReactImageVideoLightbox
            data={(shownArticle?.gallery || [])?.map((item) => {
              const { type, src: url } = item;
              return { type, url };
            })}
            startIndex={0}
            showResourceCount
            onCloseCallback={handleDismissModal}
          />
        </div>
      </Modal>
    </>
  );
};
