import React, { useEffect } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from "react-hot-loader/root";
import { Helmet } from "react-helmet";
import { Switch, Route, useLocation } from "react-router-dom";
import { initializeIcons } from "@fluentui/react/lib/Icons";

import Main from "pages/Main";

import "./App.css";

initializeIcons();

const App = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {}, []);

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>maro.page</title>
      </Helmet>
      {/* <Header /> */}
      <Switch>
        <Route path="/" exact component={Main} />
      </Switch>
      {/* <Footer /> */}
    </div>
  );
};

export default process.env.NODE_ENV === "development" ? hot(App) : App;
