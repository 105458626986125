import "core-js";
import "regenerator-runtime/runtime";
import React from "react";
import { hydrate, render } from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const browserHistory = createBrowserHistory();

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(
    <Router history={browserHistory}>
      <App />
    </Router>,
    rootElement
  );
} else {
  render(
    <Router history={browserHistory}>
      <App />
    </Router>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
