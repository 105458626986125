import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  margin: 0 auto;
  transition: width 0.3s cubic-bezier(0, 1, 0, 1);

  @media (max-width: 640px) {
    padding: 0 16px;
  }

  @media (min-width: 640px) {
    width: 608px;
  }

  @media (min-width: 1024px) {
    width: 960px;
  }

  @media (min-width: 1366px) {
    width: 1200px;
  }

  @media (min-width: 1920px) {
    width: 1440px;
  }
`;

export default Container;
