import React from "react";
import {
  DocumentCard,
  DocumentCardActivity,
  DocumentCardDetails,
  DocumentCardImage,
  DocumentCardTitle,
  ImageFit,
  Stack,
} from "@fluentui/react";
import PropTypes from "prop-types";

const Articles = ({ articles, onClick: handleClick }) => {
  return (
    <Stack
      horizontal
      horizontalAlign="start"
      verticalAlign="center"
      tokens={{ childrenGap: 16 }}
      styles={{
        root: {
          overflowX: "auto",
          width: "100%",
          paddingBottom: 16,
        },
      }}
    >
      {(articles || []).map((article) => {
        return (
          <Stack.Item>
            <DocumentCard
              styles={{
                root: {
                  display: "inline-block",
                  width: 360,
                  maxWidth: "70vw",
                },
              }}
              onClick={() => {
                handleClick(article);
              }}
            >
              <DocumentCardImage
                height={200}
                imageFit={ImageFit.centerCover}
                imageSrc={
                  article.gallery.filter(({ type }) => {
                    return type === "photo";
                  })[0].src
                }
              />
              <DocumentCardDetails>
                <DocumentCardTitle title={article.title} shouldTruncate />
              </DocumentCardDetails>
              <DocumentCardActivity
                activity={article.date}
                people={[
                  {
                    name: article.name,
                    profileImageSrc: article.iconSrc,
                  },
                ]}
              />
            </DocumentCard>
          </Stack.Item>
        );
      })}
    </Stack>
  );
};

Articles.propTypes = {
  articles: PropTypes.arrayOf({
    title: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
    iconSrc: PropTypes.string,
    gallery: PropTypes.arrayOf({
      src: PropTypes.string,
      type: PropTypes.oneOf(["photo", "video"]),
    }),
  }),
  onClick: PropTypes.func,
};

Articles.defaultProps = {
  articles: [],
  onClick: () => {},
};

export default Articles;
