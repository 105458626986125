import imgTokyo1 from "assets/images/experiences/tokyo2019/1.jpg";
import imgTokyo2 from "assets/images/experiences/tokyo2019/2.jpg";
import imgTokyo3 from "assets/images/experiences/tokyo2019/3.jpg";
import imgTokyo4 from "assets/images/experiences/tokyo2019/4.jpg";
import imgTokyo5 from "assets/images/experiences/tokyo2019/5.jpg";
import imgTokyo6 from "assets/images/experiences/tokyo2019/6.jpg";

import imgJakarta1 from "assets/images/experiences/jakarta2018/1.jpg";
import imgJakarta2 from "assets/images/experiences/jakarta2018/2.jpg";
import imgJakarta3 from "assets/images/experiences/jakarta2018/3.jpg";
import imgJakarta4 from "assets/images/experiences/jakarta2018/4.jpg";

import imgLondon1 from "assets/images/experiences/london2018/1.jpg";
import imgLondon2 from "assets/images/experiences/london2018/2.jpg";
import imgLondon3 from "assets/images/experiences/london2018/3.jpg";
import imgLondon4 from "assets/images/experiences/london2018/4.jpg";

import imgSydney1 from "assets/images/experiences/sydney2018/1.jpg";
import imgSydney2 from "assets/images/experiences/sydney2018/2.jpg";
import imgSydney3 from "assets/images/experiences/sydney2018/3.jpg";
import imgSydney4 from "assets/images/experiences/sydney2018/4.mp4";
import imgSydney5 from "assets/images/experiences/sydney2018/5.png";
import imgSydney6 from "assets/images/experiences/sydney2018/6.jpg";

import imgHongKong1 from "assets/images/experiences/hk2018/1.jpg";
import imgHongKong2 from "assets/images/experiences/hk2018/2.jpg";
import imgHongKong3 from "assets/images/experiences/hk2018/3.jpg";
import imgHongKong4 from "assets/images/experiences/hk2018/4.jpg";
import imgHongKong5 from "assets/images/experiences/hk2018/5.jpg";
import imgHongKong6 from "assets/images/experiences/hk2018/6.jpg";

import imgOsaka1 from "assets/images/experiences/osaka2017/1.mp4";
import imgOsaka2 from "assets/images/experiences/osaka2017/2.jpg";
import imgOsaka3 from "assets/images/experiences/osaka2017/3.jpg";
import imgOsaka4 from "assets/images/experiences/osaka2017/4.jpg";
import imgOsaka5 from "assets/images/experiences/osaka2017/5.jpg";
import imgOsaka6 from "assets/images/experiences/osaka2017/6.jpg";
import imgOsaka7 from "assets/images/experiences/osaka2017/7.jpg";

import imgSF1 from "assets/images/experiences/sf2016/1.jpg";
import imgSF2 from "assets/images/experiences/sf2016/2.jpg";
import imgSF3 from "assets/images/experiences/sf2016/3.jpg";
import imgSF4 from "assets/images/experiences/sf2016/4.jpg";
import imgSF5 from "assets/images/experiences/sf2016/5.jpg";

const articles = [
  {
    title: "Startup Weekend Tokyo, Foodtech",
    name: "Tokyo, Japan",
    date: "2019",
    gallery: [
      {
        type: "photo",
        src: imgTokyo1,
      },
      {
        type: "photo",
        src: imgTokyo2,
      },
      {
        type: "photo",
        src: imgTokyo3,
      },
      {
        type: "photo",
        src: imgTokyo4,
      },
      {
        type: "photo",
        src: imgTokyo5,
      },
      {
        type: "photo",
        src: imgTokyo6,
      },
    ],
  },
  {
    title: "Tech in Asia",
    name: "Jakarta, Indonesia",
    date: "2018",
    gallery: [
      {
        type: "photo",
        src: imgJakarta1,
      },
      {
        type: "photo",
        src: imgJakarta2,
      },
      {
        type: "photo",
        src: imgJakarta3,
      },
      {
        type: "photo",
        src: imgJakarta4,
      },
    ],
  },
  {
    title: "EOS Hackathon",
    name: "London, United Kingdom",
    date: "2018",
    gallery: [
      {
        type: "photo",
        src: imgLondon1,
      },
      {
        type: "photo",
        src: imgLondon2,
      },
      {
        type: "photo",
        src: imgLondon3,
      },
      {
        type: "photo",
        src: imgLondon4,
      },
    ],
  },
  {
    title: "EOS Hackathon",
    name: "Sydney, Australia",
    date: "2018",
    gallery: [
      {
        type: "photo",
        src: imgSydney1,
      },
      {
        type: "photo",
        src: imgSydney2,
      },
      {
        type: "photo",
        src: imgSydney3,
      },
      {
        type: "video",
        src: imgSydney4,
      },
      {
        type: "photo",
        src: imgSydney5,
      },
      {
        type: "photo",
        src: imgSydney6,
      },
    ],
  },
  {
    title: "EOS Hackathon",
    name: "Hong Kong",
    date: "2018",
    gallery: [
      {
        type: "photo",
        src: imgHongKong1,
      },
      {
        type: "photo",
        src: imgHongKong2,
      },
      {
        type: "photo",
        src: imgHongKong3,
      },
      {
        type: "photo",
        src: imgHongKong4,
      },
      {
        type: "photo",
        src: imgHongKong5,
      },
      {
        type: "photo",
        src: imgHongKong6,
      },
    ],
  },
  {
    title: "Startup Weekend Osaka",
    name: "Osaka, Japan",
    date: "2017",
    gallery: [
      {
        type: "video",
        src: imgOsaka1,
      },
      {
        type: "photo",
        src: imgOsaka2,
      },
      {
        type: "photo",
        src: imgOsaka3,
      },
      {
        type: "photo",
        src: imgOsaka4,
      },
      {
        type: "photo",
        src: imgOsaka5,
      },
      {
        type: "photo",
        src: imgOsaka6,
      },
      {
        type: "photo",
        src: imgOsaka7,
      },
    ],
  },
  {
    title: "Techcrunch Disrupt SF 2016",
    name: "San Francisco, United States",
    date: "2016",
    gallery: [
      {
        type: "photo",
        src: imgSF1,
      },
      {
        type: "photo",
        src: imgSF2,
      },
      {
        type: "photo",
        src: imgSF3,
      },
      {
        type: "photo",
        src: imgSF4,
      },
      {
        type: "photo",
        src: imgSF5,
      },
    ],
  },
];
export default articles;
