import { useState, useEffect, useCallback } from "react";
import { ResponsiveMode } from "@fluentui/react";

const useResponsiveMode = () => {
  const [mode, setMode] = useState(ResponsiveMode.unknown);
  // s	320px - 479px
  // m	480px - 639px
  // l	640px - 1023px
  // xl	1024px - 1365px
  // xxl	1366px - 1919px
  // xxxl	1920px and up

  const sizes = [
    {
      code: ResponsiveMode.small,
      width: 480,
    },
    {
      code: ResponsiveMode.medium,
      width: 640,
    },
    {
      code: ResponsiveMode.large,
      width: 1024,
    },
    {
      code: ResponsiveMode.xLarge,
      width: 1366,
    },
    {
      code: ResponsiveMode.xxLarge,
      width: 1920,
    },
    {
      code: ResponsiveMode.xxxLarge,
      width: 999999,
    },
  ];

  const handleResizeWindow = useCallback(() => {
    let newMode = ResponsiveMode.unknown;

    sizes.some((size) => {
      if (window.innerWidth < size.width) {
        newMode = size.code;
        return true;
      }
      return false;
    });
    setMode(newMode);
  }, [sizes]);

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    handleResizeWindow();
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [handleResizeWindow]);

  return mode;
};

export default useResponsiveMode;
