import {
  ActionButton,
  // IconButton,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import Container from "components/Container";
import React from "react";
import styled from "styled-components";

import imgLogo from "assets/images/logo.png";

const Logo = styled.div`
  width: auto;
  height: 24px;
  padding-left: 32px;
  position: relative;
  display: inline-block;
  background-image: url(${imgLogo});
  background-size: contain;
  background-position: 0 0;
  background-repeat: no-repeat;
`;

const Header = () => {
  return (
    <Container>
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        style={{ height: 120 }}
      >
        <Stack.Item>
          <Logo>
            <Text
              variant="large"
              styles={{
                root: {
                  position: "relative",
                  lineHeight: "1",
                  display: "inline-block",
                },
              }}
            >
              maro
            </Text>
          </Logo>
        </Stack.Item>
        <Stack.Item>
          <Stack
            horizontal
            horizontalAlign="end"
            verticalAlign="center"
            tokens={{ childrenGap: 4 }}
          >
            {/* <ActionButton iconProps={{ iconName: "Home" }}>Main</ActionButton> */}
            <ActionButton
              iconProps={{ iconName: "ContactInfo" }}
              href="/maro-2022-homepage.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Resume
            </ActionButton>
            <PrimaryButton
              iconProps={{ iconName: "Message" }}
              style={{ marginLeft: 4 }}
              href="mailto:honeymaro@live.co.kr"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact
            </PrimaryButton>
            {/* <IconButton iconProps={{ iconName: "Emoji2" }}>
            </IconButton> */}
          </Stack>
        </Stack.Item>
      </Stack>
    </Container>
  );
};

export default Header;
