import imgVplate1 from "assets/images/projects/vplate/1.png";
import imgVplate2 from "assets/images/projects/vplate/2.png";
import imgVplate3 from "assets/images/projects/vplate/3.png";
import imgVplate4 from "assets/images/projects/vplate/4.png";
import iconVplate from "assets/images/projects/vplate/icon.png";

import imgHupayx1 from "assets/images/projects/hupayx/1.png";
import imgHupayx2 from "assets/images/projects/hupayx/2.png";
import imgHupayx3 from "assets/images/projects/hupayx/3.png";
import imgHupayx4 from "assets/images/projects/hupayx/4.png";
import iconHupayx from "assets/images/projects/hupayx/icon.png";

import imgTrimm1 from "assets/images/projects/trimm/1.png";
import imgTrimm2 from "assets/images/projects/trimm/2.mp4";
import imgTrimm3 from "assets/images/projects/trimm/3.png";
import iconTrimm from "assets/images/projects/trimm/icon.png";

const articles = [
  {
    title: "VPLATE(브이플레이트)",
    name: "더브이플래닛",
    date: "2020",
    gallery: [
      {
        type: "photo",
        src: imgVplate1,
      },
      {
        type: "photo",
        src: imgVplate2,
      },
      {
        type: "photo",
        src: imgVplate3,
      },
      {
        type: "photo",
        src: imgVplate4,
      },
    ],
    iconSrc: iconVplate,
  },
  {
    title: "HUPAYX Explorer",
    name: "휴먼플러스",
    date: "2020",
    gallery: [
      {
        type: "photo",
        src: imgHupayx1,
      },
      {
        type: "photo",
        src: imgHupayx2,
      },
      {
        type: "photo",
        src: imgHupayx3,
      },
      {
        type: "photo",
        src: imgHupayx4,
      },
    ],
    iconSrc: iconHupayx,
  },
  {
    title: "trimm Bike",
    name: "레이시오",
    date: "2018",
    gallery: [
      {
        type: "photo",
        src: imgTrimm1,
      },
      {
        type: "video",
        src: imgTrimm2,
      },
      {
        type: "photo",
        src: imgTrimm3,
      },
    ],
    iconSrc: iconTrimm,
  },
];
export default articles;
