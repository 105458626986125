import imgA1V1 from "assets/images/awards/awards-seoul-2019-1.mp4";
import imgA1P2 from "assets/images/awards/awards-seoul-2019-2.jpg";
import imgA1P3 from "assets/images/awards/awards-seoul-2019-3.jpg";
import imgA1P4 from "assets/images/awards/awards-seoul-2019-4.jpg";
import imgA1P5 from "assets/images/awards/awards-seoul-2019-5.jpg";

import imgA2P1 from "assets/images/awards/awards-gangwon-2017-1.jpg";
import imgA2P2 from "assets/images/awards/awards-gangwon-2017-2.jpg";
import imgA2V3 from "assets/images/awards/awards-gangwon-2017-3.mp4";
import imgA2V4 from "assets/images/awards/awards-gangwon-2017-4.mp4";

import imgA3V1 from "assets/images/awards/awards-game-2019-1.mp4";
import imgA3P2 from "assets/images/awards/awards-game-2019-2.jpg";

import iconMedalGold from "assets/images/icons/icon-medal-3.png";
import iconMedalSilver from "assets/images/icons/icon-medal-4.png";

const articles = [
  {
    title: "스마트폰을 게임패드로 활용한 게임",
    name: "제 4회 게기스 X 게임캠퍼스 게임잼",
    date: "2019-12",
    prize: "우수상",
    gallery: [
      {
        type: "video",
        src: imgA3V1,
      },
      {
        type: "photo",
        src: imgA3P2,
      },
    ],
    iconSrc: iconMedalSilver,
  },
  {
    title: "마스크 자판기",
    name: "2019 SEOUL 미세먼지 해커톤대회",
    date: "2019-06",
    prize: "우수상",
    gallery: [
      {
        type: "video",
        src: imgA1V1,
      },
      {
        type: "photo",
        src: imgA1P2,
      },
      {
        type: "photo",
        src: imgA1P3,
      },
      {
        type: "photo",
        src: imgA1P4,
      },
      {
        type: "photo",
        src: imgA1P5,
      },
    ],
    iconSrc: iconMedalSilver,
  },
  {
    title: "유명 IP를 활용한 게임",
    name: "강원랜드 챌린지 메이커톤",
    date: "2017-01",
    prize: "대상",
    gallery: [
      {
        type: "video",
        src: imgA2V3,
      },
      {
        type: "photo",
        src: imgA2P2,
      },
      {
        type: "photo",
        src: imgA2P1,
      },
      {
        type: "video",
        src: imgA2V4,
      },
    ],
    iconSrc: iconMedalGold,
  },
];
export default articles;
